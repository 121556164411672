import { Injectable } from '@angular/core';

import { ApiResponse } from '@app/models/common/api-response';

@Injectable({
  providedIn: 'root',
})
// Todo: Rename and refrain fromusing new /old notations
export class NewApiUtilsService {
  parseGroupId(groupId: string | null): string {
    return groupId === null ? 'default' : groupId;
  }

  extractData<T>({ data }: ApiResponse<T>): T {
    return data;
  }
}
