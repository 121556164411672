import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { exhaustMap, map, Observable, shareReplay } from 'rxjs';

import { ApiResponse } from '@app/models/common/api-response';
import { ShopStatus } from '@app/models/interfaces/shops.interface';
import { FeedGroup, FeedGroupJobLog } from '@app/models/legacy/common/feed.model';
import { FeedJobStatus } from '@app/models/legacy/common/feedJobStatus.model';
import { Filters } from '@app/models/legacy/common/legacy-filter.model';
import {
  CalculateMapping,
  Feed,
  FeedBase,
  FeedStructure,
  Mapping,
  MappingModel,
  ValidateFeedResponse,
} from '@app/models/legacy/common/legacy-mapping.model';
import { AppState } from '@app/store/app.state';
import { selectActiveContractSid } from '@app/store/user';

import { environment } from '@environments/environment';

import { NewApiUtilsService } from './new-api-utils.service';

@Injectable({
  providedIn: 'root',
})
export class FeedGroupsService {
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private utilsService: NewApiUtilsService
  ) {}

  fetchMappingsByGroupId(groupId: string | null): Observable<Partial<Mapping>[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractSid) => {
        return this.http.get<ApiResponse<Partial<Mapping>[]>>(
          environment.pricemonitorFeApiBaseUrl +
            `/${contractSid}/import/FeedGroups/${parsedGroupId}/mappings`
        );
      }),
      map(this.utilsService.extractData<Partial<Mapping>[]>)
    );
  }

  fetchAvailableColums(groupId: string | null): Observable<MappingModel[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractSid) => {
        return this.http.get<ApiResponse<MappingModel[]>>(
          environment.pricemonitorFeApiBaseUrl +
            `/${contractSid}/import/FeedGroups/${parsedGroupId}/availableFields`
        );
      }),
      map(this.utilsService.extractData<MappingModel[]>)
    );
  }

  public fetchMappingsByShopId(groupId: string | null, shopId: string): Observable<Mapping[]> {
    return this.fetchMappingsByGroupId(groupId).pipe(
      map((mappings) => {
        return mappings.filter((mapping) => mapping.ShopId === shopId);
      })
    );
  }

  public updateMapping(mapping: any, groupId: string | null, mappingId: string): Observable<any> {
    const parseGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parseGroupId}/mappings/${mappingId}`;
        return this.http.put(url, { mapping });
      })
    );
  }

  public saveNewMapping(mapping: Mapping, groupId: string | null): Observable<CalculateMapping[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/feedGroups/${parsedGroupId}/mappings`;
        return this.http.post<ApiResponse<CalculateMapping[]>>(url, {
          mapping,
        });
      }),
      map(this.utilsService.extractData<CalculateMapping[]>)
    );
  }

  public deleteMapping(groupId: string | null, mappingId: string): Observable<any> {
    const parseGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parseGroupId}/mappings/${mappingId}`;
        return this.http.delete(url);
      })
    );
  }

  public refreshAvailableFields(groupId: string | null): Observable<string> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/availableFields/refresh`;
        return this.http.post<ApiResponse<string>>(url, {});
      }),
      map(this.utilsService.extractData<string>)
    );
  }

  public fetchFiltersPerFeedGroup(groupId: string | null): Observable<Feed[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/filters`;
        return this.http.get<ApiResponse<Feed[]>>(url);
      }),
      map(this.utilsService.extractData<Feed[]>)
    );
  }

  public saveFilters(filters: Filters[], groupId: string | null): Observable<Filters[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/feedGroups/${parsedGroupId}/filters`;
        return this.http.post<ApiResponse<Filters[]>>(url, { filters });
      }),
      map(this.utilsService.extractData<Filters[]>)
    );
  }

  public fetchFeedsByGroupId(groupId: string | null): Observable<Feed[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      shareReplay(1),
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/feeds`;
        return this.http.get<ApiResponse<Feed[]>>(url);
      }),
      map(this.utilsService.extractData<Feed[]>)
    );
  }

  public deleteFeedById(groupId: string | null, feedId: string): Observable<Feed[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/feeds/${feedId}`;
        return this.http.delete<ApiResponse<Feed[]>>(url);
      }),
      map(this.utilsService.extractData<Feed[]>)
    );
  }

  public fetchAllMappings(): Observable<Mapping[]> {
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractSid) => {
        return this.http.get<ApiResponse<Partial<Mapping>[]>>(
          environment.pricemonitorFeApiBaseUrl + `/${contractSid}/import/FeedGroups/mappings`
        );
      }),
      map(this.utilsService.extractData<Partial<Mapping>[]>)
    );
  }

  public getFeedStructure(groupId: string | null, feedId: string): Observable<FeedStructure[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractSid) => {
        return this.http.get<ApiResponse<{ Structure: FeedStructure[] }>>(
          environment.pricemonitorFeApiBaseUrl +
            `/${contractSid}/import/feedGroups/${parsedGroupId}/feeds/${feedId}/structure`
        );
      }),
      map(this.utilsService.extractData<{ Structure: FeedStructure[] }>),
      map(({ Structure }) => {
        return Structure;
      })
    );
  }

  public saveFeeds(groupId: string | null, feeds: Feed[]): Observable<Feed[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/feeds`;
        return this.http.post<ApiResponse<Feed[]>>(url, [...feeds]);
      }),
      map(this.utilsService.extractData<Feed[]>)
    );
  }

  public validateFeed(groupId: string | null, data: FeedBase): Observable<ValidateFeedResponse> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/feeds/validate`;
        return this.http.post<ApiResponse<ValidateFeedResponse>>(url, data);
      }),
      map(this.utilsService.extractData<ValidateFeedResponse>)
    );
  }

  public toggleFeedGroupStatus(group: FeedGroup): Observable<{}> {
    const parsedGroupId = this.utilsService.parseGroupId(group.Id);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/status`;
        return this.http.put<ApiResponse<{}>>(url, group);
      })
    );
  }

  public fireFeedGroupJob(groupId: string | null): Observable<{ JobId: string }> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/job/fire`;
        return this.http.post<ApiResponse<{ JobId: string }>>(url, {});
      }),
      map(this.utilsService.extractData<{ JobId: string }>)
    );
  }

  public cancelFeedGroupJob(groupId: string | null): Observable<{ JobId: string }> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/job/cancel`;
        return this.http.post<ApiResponse<{ JobId: string }>>(url, {});
      }),
      map(this.utilsService.extractData<{ JobId: string }>)
    );
  }

  public getFeedGroupJob(groupId: string | null): Observable<FeedJobStatus> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/job/status`;
        return this.http.get<ApiResponse<FeedJobStatus>>(url);
      }),
      map(this.utilsService.extractData<FeedJobStatus>)
    );
  }

  public fetchFeedGroups(): Observable<FeedGroup[]> {
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractSid) => {
        return this.http.get<ApiResponse<FeedGroup[]>>(
          environment.pricemonitorFeApiBaseUrl + `/${contractSid}/import/FeedGroups`
        );
      }),
      map(this.utilsService.extractData<FeedGroup[]>)
    );
  }

  public fetchFeedGroupJobLogs(groupId: string | null): Observable<FeedGroupJobLog[]> {
    const parsedGroupId = this.utilsService.parseGroupId(groupId);
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/${parsedGroupId}/job/logs`;
        return this.http.get<ApiResponse<FeedGroupJobLog[]>>(url);
      }),
      map(this.utilsService.extractData<FeedGroupJobLog[]>)
    );
  }

  public getShopStatus(): Observable<ShopStatus> {
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/job/status`;
        return this.http.get<ApiResponse<ShopStatus>>(url);
      }),
      map(this.utilsService.extractData<ShopStatus>)
    );
  }

  public runShop(): Observable<boolean> {
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/job/fire`;
        return this.http.post<ApiResponse<boolean>>(url, {});
      }),
      map(this.utilsService.extractData<boolean>)
    );
  }

  public cancelShop(): Observable<boolean> {
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import/FeedGroups/job/cancel`;
        return this.http.post<ApiResponse<boolean>>(url, {});
      }),
      map(this.utilsService.extractData<boolean>)
    );
  }
}
