import { Provider } from '@angular/core';

import { LegacyShopsService } from '@app/services/shops/legacy-shops.service';

import { MockChannelService } from '@services/legacy/legacy-channel/legacy-channel.mock.service';
import { ChannelService } from '@services/legacy/legacy-channel/legacy-channel.service';
import { MockMappingService } from '@services/legacy/legacy-mapping/legacy-mapping.mock.service';
import { MappingService } from '@services/legacy/legacy-mapping/legacy-mapping.service';
import { MockMarketingService } from '@services/legacy/legacy-marketing/legacy-marketing.mock.service';
import { MarketingService } from '@services/legacy/legacy-marketing/legacy-marketing.service';
import { MockReportsService } from '@services/legacy/legacy-reports/legacy-reports.mock.service';
import { ReportsService } from '@services/legacy/legacy-reports/legacy-reports.service';
import { MockStatusService } from '@services/legacy/legacy-status/legacy-status.mock.service';
import { StatusService } from '@services/legacy/legacy-status/legacy-status.service';
import { MockPortalService } from '@services/portal/portal.mock.service';
import { PortalService } from '@services/portal/portal.service';
import { MockShopsService } from '@services/shops/shops.mock.service';

export const providersList: Provider[] = [];

const getMockData = false;
const setProviders = () => {
  providersList.push([
    { provide: PortalService, useClass: MockPortalService },
    { provide: LegacyShopsService, useClass: MockShopsService },
    { provide: ReportsService, useClass: MockReportsService },
    { provide: StatusService, useClass: MockStatusService },
    { provide: ChannelService, useClass: MockChannelService },
    { provide: MarketingService, useClass: MockMarketingService },
    { provide: MappingService, useClass: MockMappingService },
  ]);
};

if (getMockData) {
  setProviders();
}
