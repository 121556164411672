import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { firstValueFrom, Observable } from 'rxjs';

import { ISshKeyPair } from '@app/components-legacy/legacy-omn-export-body/legacy-omn-export-report.model';

import { environment } from '@environments/environment';

import { CommonHttpResultInterface } from '@models/legacy/common/legacy-result.model';
import { ReportsResponseInterface } from '@models/legacy/interfaces/legacy-reports.interface';

import { IReportsService } from './legacy-reports.interface.service';

export interface SshAuthenticationKey {
  KeyId: string;
  KeyName: string;
  PrivateKeyFileEncoded: string | null;
  PrivateKeyPassphrase: string | null;
  PublicKeyFile: string;
  KeyType: number;
  NoOfBits: number;
}

export interface AuthenticationConfigurationResponse {
  SshAuthenticationKeys: SshAuthenticationKey[];
}
@Injectable({
  providedIn: 'root',
})

/**
 * @deprecated This service is deprecated and will be removed in a future version.
 * Please use the newer ReportService instead
 */
export class ReportsService implements IReportsService {
  public configuration = environment;

  public headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  constructor(private readonly http: HttpClient) {}

  loadChannelExport(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/Config/Get',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  public cloneReport$(
    baseFeedId: string,
    newChannelName: string,
    newChannelShopname: string
  ): Observable<CommonHttpResultInterface> {
    return this.http.post<CommonHttpResultInterface>(
      environment.bffLegacyEndpoint + '/feedmanager/channel/clone',
      this.provideBody({
        baseFeedId,
        newChannelName,
        newChannelShopname,
      }),
      { headers: this.headers }
    );
  }

  public deleteReport$(data: any): Observable<CommonHttpResultInterface> {
    return this.http.post<CommonHttpResultInterface>(
      environment.bffLegacyEndpoint + '/feedmanager/channel/del',
      this.provideBody(data),
      { headers: this.headers }
    );
  }

  public loadStatusDetails$(shop: any): Observable<any> {
    return this.http.post<any>(
      environment.bffLegacyEndpoint + '/feedmanager/status/get',
      this.provideBody({
        type: 'channel',
        id: shop.feed_id,
        shopname: shop.shopname,
        details: true,
      }),
      { headers: this.headers }
    );
  }

  loadChannels(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/getallchannels',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  public loadChannels$(data: { scope: string }): Observable<ReportsResponseInterface> {
    return this.http.post<ReportsResponseInterface>(
      environment.bffLegacyEndpoint + '/feedmanager/channel/getallchannels',
      this.provideBody(data),
      { headers: this.headers }
    );
  }

  public editReportName$(data: {
    name: string;
    id: string;
  }): Observable<CommonHttpResultInterface> {
    return this.http.post<CommonHttpResultInterface>(
      environment.bffLegacyEndpoint + '/feedmanager/channel/changename',
      this.provideBody(data),
      { headers: this.headers }
    );
  }

  getInternalColumn(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/getinternalcolumn',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  apiConnectedLoad(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/config/getallapis',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  getApi(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/getapi',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  setMapping(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/setmapping',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  sanitizeString(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/sanitize_string',
        this.provideBody(data),
        { headers: this.headers, responseType: 'text' }
      )
    );
  }

  async evaluate(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/feed/evaluate',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  saveTopX(data: any) {
    const method = !data.data.Id ? 'create_topx' : 'update_topx';
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/' + method,
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  deleteTopX(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/delete_topx',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  listTopX(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/list_topx',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  /**
   * @deprecated Use `createSshAuthenticationKeyObservable$` instead. we rely on observables now not promises.
   */
  createSshAuthenticationKey(data: any): any {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/config/create_ssh_authentication_key',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  createSshAuthenticationKeyObservable(data: any): Observable<any> {
    return this.http.post(
      environment.bffLegacyEndpoint + '/feedmanager/config/create_ssh_authentication_key',
      this.provideBody(data),
      { headers: this.headers }
    );
  }

  getBidsSetting(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/channel/getbidssetting',
        this.provideBody(data),
        { headers: this.headers }
      )
    );
  }

  history(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/status/history',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  /**
   * @deprecated Use `getAuthenticationConfigurationObservable$` instead. we rely on observables now not promises.
   */
  getAuthenticationConfiguration(): any {
    return firstValueFrom(
      this.http.post<{ SshAuthenticationKeys: ISshKeyPair[] }>(
        environment.bffLegacyEndpoint + '/feedmanager/config/get_authentication_configuration',
        { headers: this.headers }
      )
    );
  }

  getAuthenticationConfigurationObservable(): Observable<AuthenticationConfigurationResponse> {
    return this.http.post<AuthenticationConfigurationResponse>(
      environment.bffLegacyEndpoint + '/feedmanager/config/get_authentication_configuration',
      { headers: this.headers }
    );
  }

  feedContent(data: any) {
    return firstValueFrom(
      this.http.post(
        environment.bffLegacyEndpoint + '/feedmanager/feed/preview',
        this.provideBody(data),
        {
          headers: this.headers,
        }
      )
    );
  }

  getPreviewData(url: any) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(url, { headers, responseType: 'text' });
  }

  provideBody(data: any) {
    return Object.keys(data)
      .map((key) => {
        return [key, JSON.stringify(data[key])].map(encodeURIComponent).join('=');
      })
      .join('&');
  }
}
