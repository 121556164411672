import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { exhaustMap, map, Observable } from 'rxjs';

import { ApiResponse } from '@app/models/common/api-response';
import { AppState } from '@app/store/app.state';
import { selectActiveContractSid } from '@app/store/user';

import { environment } from '@environments/environment';

import { Shop } from '@models/interfaces/shops.interface';

import { NewApiUtilsService } from '../fe-api-new/new-api-utils.service';

@Injectable({
  providedIn: 'root',
})
export class ShopsService {
  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
    private utilsService: NewApiUtilsService
  ) {}

  public getShops(): Observable<Shop[]> {
    return this.store.select(selectActiveContractSid).pipe(
      exhaustMap((contractId) => {
        const url = `${environment.pricemonitorFeApiBaseUrl}/${contractId}/import-export/shops`;
        return this.http.get<ApiResponse<Shop[]>>(url);
      }),
      map(this.utilsService.extractData<Shop[]>)
    );
  }
}
