import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
  catchError,
  delay,
  exhaustMap,
  map,
  Observable,
  of,
  switchMap,
  take,
  timer,
  withLatestFrom,
} from 'rxjs';

import { FeedGroupsService } from '@app/services/fe-api-new/feedGroups.service';
import { ShopsService } from '@app/services/fe-api-new/shops.service';

import { legacyApiCallsAction } from '@store/legacy';
import {
  cancelShopRun,
  getShopStatus,
  loadShopsAndStatus,
  selectShopInProgress,
  triggerShopRun,
} from '@store/product-import';

import { AppState } from '../app.state';
import { SnackbarErrorAction } from '../snackbar';

@Injectable()
export class ProductImportEffects {
  constructor(
    private readonly shopService: ShopsService,
    private readonly actions$: Actions,
    private readonly feedGroupsService: FeedGroupsService,
    private readonly store: Store<AppState>
  ) {}

  public readonly getShopsAndStatus$ = createEffect(() => this.getShopsAndStatus());

  public readonly runShop$ = createEffect(() => this.runShop());

  public readonly cancelShopRun$ = createEffect(() => this.cancelShopRun());

  public readonly getShopStatus$ = createEffect(() => this.getShopStatus());

  public readonly getShopStatusAfterRunShop$ = createEffect(() => this.getShopStatusAfterRunShop());

  public readonly handleRunError$ = createEffect(() => this.handleRunError());

  private getShopsAndStatus(): Observable<Action> {
    return this.actions$.pipe(
      ofType(legacyApiCallsAction.success),
      map(({ shop }) => loadShopsAndStatus.success({ shop })),
      catchError(() => of(loadShopsAndStatus.failed()))
    );
  }

  private runShop(): Observable<Action> {
    return this.actions$.pipe(
      ofType(triggerShopRun.init),
      switchMap((action) =>
        this.feedGroupsService.runShop().pipe(
          map(() => triggerShopRun.success({ shopName: action.shopName })),
          catchError(() => of(triggerShopRun.failed()))
        )
      )
    );
  }

  private handleRunError(): Observable<Action> {
    return this.actions$.pipe(
      ofType(triggerShopRun.failed),
      map(() =>
        SnackbarErrorAction({
          message: `Failed to trigger the import, please try again later. If the problem persists, please <a href="mailto:customerservice@omniaretail.com">contact us</a>.`,
          hasHtml: true,
        })
      )
    );
  }

  /**
   * After the success of run shop or success of cancel shop,
   * shop status needs to be updated
   * @remark delay of 5sec is added to show cancelling state as in Omnia-portal
   */
  private getShopStatusAfterRunShop(): Observable<Action> {
    return this.actions$.pipe(
      ofType(triggerShopRun.success, cancelShopRun.success),
      switchMap((action) => of(getShopStatus.init({ shopName: action.shopName })))
    );
  }

  /**
   * This effect is used to poll shop status and update the store.
   * Since we need different timeouts for long and short polling, we only take
   * one value from the service call and re-run the effect checking the status of the job to set the short or long polling time.
   */
  private getShopStatus(): Observable<Action> {
    return this.actions$.pipe(
      ofType(
        getShopStatus.init,
        getShopStatus.success,
        loadShopsAndStatus.success,
        triggerShopRun.failed
      ),
      withLatestFrom(this.store.select(selectShopInProgress)),
      switchMap(([{ _ }, inProgress]) => {
        const updateTime = inProgress ? 5000 : 20000;
        //const shortTimer = 5000;
        return timer(0, updateTime).pipe(
          take(1),
          delay(updateTime),
          exhaustMap(() =>
            this.feedGroupsService.getShopStatus().pipe(
              map((status) => {
                return getShopStatus.success({ shop: { status } });
              }),
              catchError(() => of(getShopStatus.failed()))
            )
          )
        );
      })
    );
  }

  private cancelShopRun(): Observable<Action> {
    return this.actions$.pipe(
      ofType(cancelShopRun.init),
      switchMap((action) =>
        this.feedGroupsService.cancelShop().pipe(
          map(() => cancelShopRun.success({ shopName: action.shopName })),
          catchError(() => of(cancelShopRun.failed()))
        )
      )
    );
  }
}
